import React from "react";
import { Button } from "@mui/material";
import ModalRoomAction from "./ModalRoomAction";

const ButtonRoom = ({ room }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => setModalOpen(true)}
        sx={{
          mt: 1,
          mb: 1,
          p: 1.5,
          width: "100%",
        }}
      >
        {room.pl}
      </Button>
      <ModalRoomAction modalOpen={modalOpen} closeModal={() => setModalOpen(false)} room={room} />
    </>
  );
};

export default ButtonRoom;
