import React from "react";
import { socket } from "../socket";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const ModalRoomAction = ({ room, modalOpen, closeModal }) => {
  let sendRoom = action => {
    let data = {
      room: room,
      action,
    };
    socket.emit("rolety", data);

    closeModal();
  };

  return (
    <Dialog maxWidth={"sm"} fullWidth={true} open={modalOpen} onClose={closeModal} sx={{ m: -3 }}>
      <DialogTitle>{room.pl}</DialogTitle>
      <DialogContent sx={{ pr: 1, pl: 1 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => sendRoom("open")}
          sx={{ p: 1.5, mb: 2, width: "100%" }}
        >
          Otwórz
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ p: 1.5, width: "100%" }}
          onClick={() => sendRoom("close")}
        >
          Zamknij
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalRoomAction;
