import GlobalAlerts from "./components/Alerts";
import { io } from "socket.io-client";

const apiUrl = process.env.REACT_APP_BACKEND_ADDRESS;

let socket;

function setupSocket(logOut) {
  socket = io(apiUrl, {
    query: {
      token: localStorage.getItem("token"),
    },
  });

  console.log("connecting to the socket");

  socket.on("validated", data => {
    GlobalAlerts.emit(data);
  });

  socket.on("error", error => {
    if (error === "wrong token") {
      GlobalAlerts.emit("Session has expired");
      logOut();
    }
    console.log("Socket error: ", error);
  });

  socket.on("alert", data => {
    console.log("Received alert: ", data);
    GlobalAlerts.emit(data);
  });

  socket.on("espError", data => {
    console.log("Received ESP Error: ", data);
    GlobalAlerts.emit("ESP Error");
  });

  socket.on("msg", data => {
    console.log("msg from server: ", data);
  });
}

export { socket, setupSocket };
