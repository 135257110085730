import React from "react";

import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import {
  Home as HomeIcon,
  Person as PersonIcon,
  PersonAdd as PersonAddIcon,
} from "@mui/icons-material";

import { Link } from "react-router-dom";

const items = [
  {
    icon: HomeIcon,
    text: "Rolety",
    link: "/",
  },
  {
    icon: PersonIcon,
    text: "Logowanie",
    link: "/login",
  },
  {
    icon: PersonAddIcon,
    text: "Rejestracja",
    link: "/register",
  },
];

const DrawerLinks = () => {
  return (
    <List>
      {items.map((item, index) => (
        <ListItem button component={Link} to={item.link} key={index}>
          <ListItemIcon>
            <item.icon />
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );
};

export default DrawerLinks;
