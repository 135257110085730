import React, { useRef, useEffect, useState } from "react";
import ConsecutiveSnackbar from "./ConsecutiveSnackbar";

export const Alerts = () => {
  const alertQueue = useRef([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const processQueue = () => {
    if (alertQueue.current.length > 0) {
      setAlertMessage(alertQueue.current.shift());
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    const showAlert = message => {
      alertQueue.current.push({
        message,
        key: new Date().getTime(),
      });
      if (alertOpen) {
        setAlertOpen(false);
      } else {
        processQueue();
      }
    };

    GlobalAlerts.subscribe(showAlert);
    return () => GlobalAlerts.unsubscribe(showAlert);
  }, [alertOpen]);

  return (
    <ConsecutiveSnackbar
      open={alertOpen}
      setOpen={setAlertOpen}
      processQueue={processQueue}
      messageInfo={alertMessage}
    />
  );
};

const createMessageQueue = () => {
  let callbacks = [];

  return {
    emit: message => callbacks.forEach(call => call(message)),
    subscribe: newCallback => (callbacks = [...callbacks, newCallback]),
    unsubscribe: callbackToRemove =>
      (callbacks = callbacks.filter(callback => callback !== callbackToRemove)),
  };
};

const GlobalAlerts = createMessageQueue();

export default GlobalAlerts;
