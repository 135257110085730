import React from "react";

import { socket, setupSocket } from "../socket";

import { CircularProgress } from "@mui/material";

import ButtonRoom from "./ButtonRoom";
import GlobalAlerts from "./Alerts";
const apiUrl = process.env.REACT_APP_BACKEND_ADDRESS;

const Home = ({ logOut }) => {
  const [state, setState] = React.useState({
    error: null,
    isLoaded: false,
    roomList: null,
  });

  // Get room list from API when component is loaded
  React.useEffect(() => {
    // Chcek if there is a token
    if (!localStorage.getItem("token")) {
      logOut();
    }

    fetch(`${apiUrl}/getrooms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    })
      .then(res => res.json())
      .then(result => {
        setState({ isLoaded: true, roomList: result });
      })
      .catch(error => {
        setState({ isLoaded: true, error: error });
      });
  }, [logOut]);

  // Try to connect to socket if not connected
  if (!socket && localStorage.getItem("token")) {
    GlobalAlerts.emit("Connecting to the server ...");
    setupSocket(logOut);
    return null;
  }

  const { error, isLoaded, roomList } = state;

  // Check if there was an error in API call
  if (error) return <div>Error: {error.message}</div>;

  // Display a loading icon if it's loading
  if (!isLoaded) return <CircularProgress />;

  // Render after fetch is successfull
  return (
    <>
      {roomList.map(r => (
        <ButtonRoom room={r} key={r.name} />
      ))}
    </>
  );
};

export default Home;
