import React from "react";
import { Snackbar, IconButton } from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

const ConsecutiveSnackbars = ({ setOpen, processQueue, messageInfo, open }) => {
  const handleClose = reason => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    processQueue();
  };

  return (
    <>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        onExited={handleExited}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{messageInfo ? messageInfo.message : undefined}</span>}
        action={
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        }
      />
    </>
  );
};

export default ConsecutiveSnackbars;
