import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, teal } from "@mui/material/colors";
import { Box } from "@mui/material";

import NavBar from "./components/NavBar";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Home from "./components/Home";
import { Alerts } from "./components/Alerts";

const theme = createTheme({
  palette: {
    primary: teal,
    secondary: grey,
    spacing: 4,
  },
});

const App = () => {
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    navigate("/login");
  };

  return (
    <ThemeProvider theme={theme}>
      <NavBar logOut={logOut} />

      <Box
        sx={{
          p: 2,
          margin: "auto auto",
          maxWidth: "1500px",
        }}
      >
        <Alerts />
        <Routes>
          <Route exact path="/" element={<Home logOut={logOut} />} />

          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<SignUp />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
};

export default App;
