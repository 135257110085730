import React from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import DrawerLinks from "./DrawerLinks";

const NavDrawer = ({ setOpen, open }) => {
  return (
    <SwipeableDrawer open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
      <Box
        role="presentation"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
        sx={{ width: 240 }}
      >
        <DrawerLinks />
      </Box>
    </SwipeableDrawer>
  );
};

export default NavDrawer;
