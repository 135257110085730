import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";

import GlobalAlerts from "./Alerts";

import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";

const apiUrl = process.env.REACT_APP_BACKEND_ADDRESS;

const SignInPage = () => {
  const [state, setState] = React.useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleSubmit = event => {
    event.preventDefault();

    const url = `${apiUrl}/user/login`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify(state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => res.json())
      .then(response => {
        console.log(response);
        // Check for errors and display them if there are any
        if (response.error) {
          GlobalAlerts.emit(response.error, "info");
          return;
        }

        GlobalAlerts.emit("Successfully logged in", "primary");
        localStorage.setItem("token", response.token);
        localStorage.setItem("name", response.name);
        navigate("/");
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={state.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={state.password}
            onChange={handleInputChange}
          />

          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
            Log In
          </Button>
          <Grid container justifyContent="center" mb={2}>
            <Grid item>
              <Link variant="body2" onClick={() => navigate("/register")}>
                {"Don't have an account? Register"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default SignInPage;
